<template>
    <div class="control-wrapper">
        <div class="control-main">
            <header class="header-wrapper">
                <div class="block-item left_block"></div>
                <div class="block-item center_block">
                    <p>
                        合同管控<span v-for="(item,index) in totalArr" :key="index">{{ item }}</span> 个
                    </p>
                </div>
                <div class="block-item right_block">
                    <el-date-picker
                        v-model="timeArray"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        @change="timeChange"
                    >
                    </el-date-picker>
                </div>
            </header>
            <div class="content-wrapper">
                <div class="content-item left_content">
                    <p>合同类型</p>
                    <div
                        class="chart"
                        id="myChart1"
                    ></div>
                </div>
                <div class="content-item center_content">
                    <p>工程类型</p>
                    <div
                        class="chart"
                        id="myChart2"
                    ></div>
                </div>
                <div class="content-item right_content">
                    <p>管控状态</p>
                    <div
                        class="chart"
                        id="myChart3"
                    ></div>
                </div>
            </div>
            <div class="section-wrapper">
                <div class="table-wrapper table-bg">
                    <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        height="100%"
                    >
                        <!-- <el-table-column
                            type="index"
                            label="序号"
                        >
                        </el-table-column> -->
                        <el-table-column
                            prop="control_instance_code"
                            label="编号"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="control_name"
                            label="管控点名称"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="contract_code"
                            label="合同编号"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="contract_type_name"
                            label="合同类型"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="client_name"
                            label="客户名称"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="engineering_name"
                            label="工程名称"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="engineering_type_name"
                            label="管控类型"
                            width="80"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="control_status_name"
                            label="管控状态"
                            width="80"
                        >
                        </el-table-column>
                        <el-table-column
                            label="预警提醒"
                            width="80"
                        >
                            <template slot-scope="scope">
                                <el-button type="text" @click="lookUpChange(scope.row,'管控通知',1)">
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="管控通知"
                            width="80"
                        >
                            <template slot-scope="scope">
                                <el-button type="text" @click="lookUpChange(scope.row,'管控通知',1)">
                                    查看
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="管控处置"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    v-if="scope.row.control_status !== 4"
                                    type="text"
                                    @click="lookUpChange(scope.row,'解锁对话框',2)"
                                    style="color:#FF6600"
                                >
                                    解锁
                                </el-button>
                                <el-button
                                    v-if="scope.row.control_status === 4"
                                    type="text"
                                    @click="lookUpChange(scope.row,'解锁对话框',2)"
                                >
                                    查看
                                </el-button>
                                <el-button
                                    v-if="scope.row.control_status === 4"
                                    type="text"
                                    @click="locking(scope.row)"
                                >
                                    锁定
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page-wrapper table-page">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.pageindex"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="page.pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="maske" v-show="isShow"></div>
        <div class="dialog-wrapper" v-show="isShow">
            <div class="dialog-box">
                <span></span>
                <div class="header-title">
                    <h4>{{ titleName }}</h4>
                </div>
                <div class="section-main">
                    <ul class="list">
                        <li class="item" v-for="(item,index) in ControlData" :key="index">
                            <div class="item-box" :class="itemLogoClassName(item)">
                                <div class="left-logo">
                                    <div class="logo-wrapper">
                                        <div class="logo-bg">
                                            <i v-if="item.group === 301" class="iconfont iconyujing"></i>
                                            <i v-else-if="item.group === 201" class="">管控</i>
                                            <i v-else class=""></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-main">
                                    <h6 v-text="textChange(item)">
                                    </h6>
                                    <em>{{ item.created }}</em>
                                    <p>
                                        {{ item.title }}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="item" v-for="(jtem,jndex) in jsData" :key="jndex">
                            <div class="item-box js">
                                <div class="left-logo">
                                    <div class="logo-wrapper">
                                        <div class="logo-bg">
                                            <i class="">解锁</i>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-main">
                                    <h6>解锁信息</h6>
                                    <!-- <em>{{ jtem.disposal_time }}</em> -->
                                    <p>{{ jtem.disposal_description }}</p>
                                    <div class="operator-wrapper">
                                        <span class="operator"><i class="iconfont iconkehu1"></i> {{ jtem.disposal_user_name }}</span>
                                        <span class="time"><i class="iconfont iconshijian"></i> {{ jtem.disposal_time }}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="textarea-wrapper" v-show="isInput">
                    <div class="textarea-box">
                        <el-input
                            type="textarea"
                            placeholder="请输入内容"
                            :autosize="{ minRows: 4, maxRows: 4}"
                            v-model="confirmForm.disposal_description"
                            maxlength="100"
                            show-word-limit
                        >
                        </el-input>
                    </div>
                </div>
                <div class="footer-btn">
                    <button class="btn" @click="closeChange" v-show="isBtn1">
                        关闭
                    </button>
                    <button class="btn confirm_btn" @click="confirmChange" v-show="isBtn2">
                        确定
                    </button>
                    <button class="btn" @click="closeChange" v-show="isBtn2">
                        取消
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import imgSrc from './gkImg.js';
import bg1 from './../../assets/images/gk/bg1.png';
import bg2 from './../../assets/images/gk/bg2.png';
import bg3 from './../../assets/images/gk/bg3.png';
import bg4 from './../../assets/images/gk/bg4.png';
import bg5 from './../../assets/images/gk/bg5.png';
import bg6 from './../../assets/images/gk/bg6.png';
import bg7 from './../../assets/images/gk/bg7.png';
import bg8 from './../../assets/images/gk/bg8.png';
import bg9 from './../../assets/images/gk/bg9.png';
import bg10 from './../../assets/images/gk/bg10.png';
import bg11 from './../../assets/images/gk/bg11.png';
import bg12 from './../../assets/images/gk/bg12.png';
import bg13 from './../../assets/images/gk/bg13.png';
import bg14 from './../../assets/images/gk/bg14.png';
import bg15 from './../../assets/images/gk/bg15.png';
import circle1 from './../../assets/images/gk/circle1.png';
import circle2 from './../../assets/images/gk/circle2.png';
import circle3 from './../../assets/images/gk/circle3.png';
import circle4 from './../../assets/images/gk/circle4.png';
// import line1 from './../../assets/images/gk/line1.png';
// import line2 from './../../assets/images/gk/line2.png';
// import line3 from './../../assets/images/gk/line3.png';
// import line4 from './../../assets/images/gk/line4.png';
export default {
    data() {
        return {
            timeArray: [this.timeFormat(new Date(new Date().getTime() - 3600 * 1000 * 24 * 6)), this.timeFormat(new Date())], // 时间数组
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    },
                }],
            },
            // 当前页码
            currentPage: 1,
            // 列表数据
            tableData: [],
            // 列表总条数
            total: 0,
            // 管控合同个数
            totalArr: [],
            // 查询对象
            formInline: {},
            // 分页
            page: {
                pageindex: 1,
                pagesize: 10,
            },
            contractTypeData: [], // 合同类型
            engineeringTypeData: [], // 工程类型
            controlStatusData: [], // 管控状态
            // 控制弹框显示
            isShow: false,
            // 弹窗title
            titleName: '',
            // 解锁提交的信息
            confirmForm: {
                control_instance_id: '',
                disposal_description: '',
            },
            jsData: [],
            ControlData: [],
            isInput: false,
            isBtn1: false,
            isBtn2: false,
        };
    },
    created() {

    },
    mounted() {
        this.init();
    },
    methods: {
        textChange(item) {
            const arr = {
                101: '消息通知',
                201: '管控消息',
                301: '预警消息',
                401: '控制提醒',
            };
            return arr[item.group];
        },
        itemLogoClassName(item) {
            if (item.group === 201) {
                return 'gk';
            } if (item.group === 301) {
                return 'yj';
            }
            return '';
        },
        // 确定提交
        confirmChange() {
            this.$axios
                .post('/interfaceApi/sale/control/manually_unlock', this.confirmForm)
                .then(() => {
                    this.$message({
                        type: 'success',
                        message: '解锁成功!',
                    });
                    this.isShow = false;
                    this.titleName = '';
                    this.confirmForm = {
                        control_instance_id: '',
                        disposal_description: '',
                    };
                    this.getTableData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        closeChange() {
            this.isShow = false;
            this.jsData = [];
            this.ControlData = [];
        },
        lookUpChange(item, name, type) {
            this.titleName = name;
            this.isShow = true;
            if (type === 1) {
                this.isInput = false;
                this.isBtn2 = false;
                this.isBtn1 = true;
                this.getControlList(item);
            } else if (type === 2) {
                if (item.control_status === 4) {
                    this.isInput = false;
                    this.isBtn2 = false;
                    this.isBtn1 = true;
                } else {
                    this.confirmForm.control_instance_id = item.cciid;
                    this.isInput = true;
                    this.isBtn2 = true;
                    this.isBtn1 = false;
                }
                this.getControlList(item);
                this.gitJSList(item);
            }
        },
        // 锁定操作
        locking(row) {
            this.$confirm('是否锁定该合同？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post('/interfaceApi/sale/control/manually_lock/' + row.cciid)
                    .then(() => {
                        this.$message({
                            type: 'success',
                            message: '锁定成功!',
                        });
                        this.getTableData();
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
        },
        // 拉去解锁消息
        gitJSList(item) {
            this.$axios
                .get('/interfaceApi/sale/control/disposelist/' + item.cciid)
                .then(res => {
                    this.jsData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉去管控消息
        getControlList(item) {
            this.$axios
                .get(`/interfaceApi/message/MessageInfo/get_notice_page?tenant_id=${
                    this.$takeTokenParameters('TenantId')}&key_id=${item.control_instance_code}&template_code=&pageindex=1&pagesize=100`)
                .then(res => {
                    this.ControlData = res.rows;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        init() {
            this.getCount();
            this.getTableData();
        },
        timeChange() {
            this.init();
        },
        getCount() {
            if (this.timeArray.length > 0) {
                this.formInline.start_time = this.timeArray[0] + ' 00:00:00';
                this.formInline.end_time = this.timeArray[1] + ' 23:59:59';
            }
            this.$axios
                .post('/interfaceApi/sale/control/statistics', this.formInline)
                .then(res => {
                    res.map(item => {
                        if (item.type === 'contract_type') {
                            this.contractTypeData = item.data;
                        } else if (item.type === 'engineering_type') {
                            this.engineeringTypeData = item.data;
                        } else if (item.type === 'control_status') {
                            this.controlStatusData = item.data;
                        }

                    });
                    this.initCharts();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getTableData() {
            if (this.timeArray.length > 0) {
                this.formInline.start_time = this.timeArray[0] + ' 00:00:00';
                this.formInline.end_time = this.timeArray[1] + ' 23:59:59';
            }
            this.$axios
                .post('/interfaceApi/sale/control/instance_page', {
                    ...this.formInline,
                    ...this.page,
                })
                .then(res => {
                    this.tableData = res.rows;
                    this.total = res.total;
                    this.totalArr = String(res.total).split('');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        initCharts() {
            this.Chart1();
            this.Chart2();
            this.Chart3();
        },
        Chart3() {
            const myChart = this.$echarts.init(document.getElementById('myChart3'));
            const dashedPic = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAAOBAMAAAB6G1V9AAAAD1BMVEX////'
            + 'Kysrk5OTj4+TJycoJ0iFPAAAAG0lEQVQ4y2MYBaNgGAMTQQVFOiABhlEwCugOAMqzCykGOeENAAAAAElFTkSuQmCC';
            const color = ['#FF8700', '#ffc300', '#00e473', '#009DFF'];
            const colors = [
                ['#3E78C3', '#44A1F5'],
                ['#EA8343', '#F0AD55'],
                ['#499F93', '#76CAB3'],
                ['#48A0D8', '#7FD0FE'],
            ];
            const arrName = [];
            const arrValue = [];
            let sum = 0;
            const pieSeries = [];
            const lineYAxis = [];
            const chartData = [];
            this.controlStatusData.forEach((item, index) => {
                const currentColor = colors[index % 4];
                chartData.push({
                    name: item.name,
                    value: item.value,
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                offset: 0,
                                color: currentColor[0],
                            }, {
                                offset: 1,
                                color: currentColor[1],
                            }]),
                        },
                    },
                });
            });
            // 数据处理
            chartData.forEach(v => {
                arrName.push(v.name);
                arrValue.push(v.value);
                sum = sum + v.value;
            });
            // 图表option整理
            chartData.forEach((v, i) => {
                pieSeries.push({
                    name: '',
                    type: 'pie',
                    clockWise: false,
                    hoverAnimation: false,
                    radius: [100 - i * 15 + '%', 87 - i * 15 + '%'],
                    center: ['30%', '50%'],
                    label: {
                        show: false,
                    },
                    data: [{
                        value: v.value,
                        name: v.name,
                        itemStyle: v.itemStyle,
                    }, {
                        value: sum - v.value,
                        name: '',
                        itemStyle: {
                            color: 'rgba(0,0,0,0)',
                        },
                    }],
                });
                pieSeries.push({
                    name: '',
                    type: 'pie',
                    silent: true,
                    z: 1,
                    clockWise: false, // 顺时加载
                    hoverAnimation: false, // 鼠标移入变大
                    radius: [100 - i * 15 + '%', 87 - i * 15 + '%'],
                    center: ['30%', '50%'],
                    label: {
                        show: false,
                    },
                    data: [{
                        value: 7.5,
                        itemStyle: {
                            color: '#E3F0FF',
                        },
                    }, {
                        value: 2.5,
                        name: '',
                        itemStyle: {
                            color: 'rgba(0,0,0,0)',
                        },
                    }],
                });
                if (sum > 0) {
                    v.percent = (v.value / sum * 100).toFixed(1) + '%';
                } else {
                    v.percent = '100%';
                }
                lineYAxis.push({
                    value: i,
                    textStyle: {
                        rich: {
                            circle: {
                                color: color[i],
                                // padding: [0, 5],
                            },
                        },
                    },
                });
            });

            const option = {
                color: color,
                grid: {
                    top: '0%',
                    bottom: '56%',
                    left: '30%',
                    containLabel: true,
                },
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (params) {
                            const item = chartData[params];
                            let str = '';
                            if (params % 4 === 0) {
                                str = '{line|}{circle1|}{name|' + item.name + '} {percent1|' + item.percent + '} {percent1|' + item.value + '个}';
                            } else if (params % 4 === 1) {
                                str = '{line|}{circle2|}{name|' + item.name + '} {percent2|' + item.percent + '} {percent2|' + item.value + '个}';
                            } else if (params % 4 === 2) {
                                str = '{line|}{circle3|}{name|' + item.name + '} {percent3|' + item.percent + '} {percent3|' + item.value + '个}';
                            } else if (params % 4 === 3) {
                                str = '{line|}{circle4|}{name|' + item.name + '} {percent4|' + item.percent + '} {percent4|' + item.value + '个}';
                            }
                            return str;
                        },
                        interval: 0,
                        inside: true,
                        textStyle: {
                            rich: {
                                circle1: {
                                    backgroundColor: { image: circle4 },
                                },
                                circle2: {
                                    backgroundColor: { image: circle3 },
                                },
                                circle3: {
                                    backgroundColor: { image: circle2 },
                                },
                                circle4: {
                                    backgroundColor: { image: circle1 },
                                },
                                line: {
                                    width: 100,
                                    backgroundColor: { image: dashedPic },
                                },
                                line1: {
                                    width: 40,
                                    height: 60,
                                },
                                line2: {
                                    width: 40,
                                    height: 20,
                                },
                                line3: {
                                    width: 40,
                                    height: 10,
                                },
                                line4: {
                                    width: 40,
                                    height: 1,
                                },
                                name: {
                                    fontSize: 14,
                                    padding: [0, 0, 0, 10],
                                },
                                percent1: {
                                    color: '#3F7BC7',
                                    fontSize: 12,
                                    backgroundColor: { image: bg15 },
                                    height: 16,
                                    align: 'center',
                                },
                                percent2: {
                                    color: '#FC863C',
                                    fontSize: 12,
                                    backgroundColor: { image: bg14 },
                                    height: 16,
                                    align: 'center',
                                },
                                percent3: {
                                    color: '#4EA396',
                                    fontSize: 12,
                                    backgroundColor: { image: bg13 },
                                    height: 16,
                                    align: 'center',
                                },
                                percent4: {
                                    color: '#2AA0E9',
                                    fontSize: 12,
                                    backgroundColor: { image: bg12 },
                                    height: 16,
                                    align: 'center',
                                },
                                value: {
                                    color: '#333',
                                    fontSize: 12,
                                    backgroundColor: { image: bg12 },
                                    height: 16,
                                    align: 'center',
                                },
                            },
                        },
                        show: true,
                    },
                    data: lineYAxis,
                }],
                xAxis: [{
                    show: false,
                }],
                series: pieSeries,
            };
            myChart.setOption(option);
        },
        Chart2() {
            const myChart = this.$echarts.init(document.getElementById('myChart2'));
            const xdata = [];
            const data = [{
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#55A7FE',
                        }, {
                            offset: 1,
                            color: '#3E79C5',
                        }]),
                    },
                },
                label: {
                    color: '#55A7FE',
                    backgroundColor: {
                        image: bg5,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#EFAB54',
                        }, {
                            offset: 1,
                            color: '#FA8B47',
                        }]),
                    },
                },
                label: {
                    color: '#EFAB54',
                    backgroundColor: {
                        image: bg6,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#73C8B1',
                        }, {
                            offset: 1,
                            color: '#4CA295',
                        }]),
                    },
                },
                label: {
                    color: '#73C8B1',
                    backgroundColor: {
                        image: bg7,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#FA9E94',
                        }, {
                            offset: 1,
                            color: '#E44F4F',
                        }]),
                    },
                },
                label: {
                    color: '#FA9E94',
                    backgroundColor: {
                        image: bg8,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#7ACCFB',
                        }, {
                            offset: 1,
                            color: '#2AA0E9',
                        }]),
                    },
                },
                label: {
                    color: '#7ACCFB',
                    backgroundColor: {
                        image: bg9,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#76E0A8',
                        }, {
                            offset: 1,
                            color: '#1CC96E',
                        }]),
                    },
                },
                label: {
                    color: '#76E0A8',
                    backgroundColor: {
                        image: bg10,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#C896FC',
                        }, {
                            offset: 1,
                            color: '#9759D6',
                        }]),
                    },
                },
                label: {
                    color: '#C896FC',
                    backgroundColor: {
                        image: bg11,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#55A7FE',
                        }, {
                            offset: 1,
                            color: '#3E79C5',
                        }]),
                    },
                },
                label: {
                    color: '#55A7FE',
                    backgroundColor: {
                        image: bg5,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#EFAB54',
                        }, {
                            offset: 1,
                            color: '#FA8B47',
                        }]),
                    },
                },
                label: {
                    color: '#EFAB54',
                    backgroundColor: {
                        image: bg6,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#73C8B1',
                        }, {
                            offset: 1,
                            color: '#4CA295',
                        }]),
                    },
                },
                label: {
                    color: '#73C8B1',
                    backgroundColor: {
                        image: bg7,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#FA9E94',
                        }, {
                            offset: 1,
                            color: '#E44F4F',
                        }]),
                    },
                },
                label: {
                    color: '#FA9E94',
                    backgroundColor: {
                        image: bg8,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#7ACCFB',
                        }, {
                            offset: 1,
                            color: '#2AA0E9',
                        }]),
                    },
                },
                label: {
                    color: '#7ACCFB',
                    backgroundColor: {
                        image: bg9,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#76E0A8',
                        }, {
                            offset: 1,
                            color: '#1CC96E',
                        }]),
                    },
                },
                label: {
                    color: '#76E0A8',
                    backgroundColor: {
                        image: bg10,
                    },
                },
            }, {
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                            offset: 0,
                            color: '#C896FC',
                        }, {
                            offset: 1,
                            color: '#9759D6',
                        }]),
                    },
                },
                label: {
                    color: '#C896FC',
                    backgroundColor: {
                        image: bg11,
                    },
                },
            }];
            this.engineeringTypeData.map((item, index) => {
                xdata.push(item.name);
                data[index].value = item.value;
            });
            const option = {
                backgroundColor: '#ffffff',
                grid: {
                    left: '0%',
                    top: '10%',
                    bottom: '16%',
                    right: '0%',
                },
                xAxis: {
                    data: xdata,
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255, 129, 109, 0.1)',
                            width: 1, // 这里是为了突出显示加上的
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#999',
                            fontSize: 12,
                        },
                    },
                },
                yAxis: [{
                    splitNumber: 2,
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(255, 129, 109, 0.1)',
                            width: 1, // 这里是为了突出显示加上的
                        },
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: '#999',
                        },
                    },
                    splitArea: {
                        areaStyle: {
                            color: 'rgba(255,255,255,.5)',
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(232,232,232,1)',
                            width: 0.5,
                            type: 'dashed',
                        },
                    },
                },
                ],
                series: [{
                    name: '',
                    type: 'pictorialBar',
                    barCategoryGap: '50%',
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    label: {
                        show: true,
                        position: 'top',
                        distance: 0,
                        color: '#DB5E6A',
                        fontWeight: 'bolder',
                        fontSize: 12,
                        // formatter: function (obj) {
                        //     return obj.value + '个';
                        // },
                        formatter: '{c}个',
                        width: 38,
                        height: 22,
                        align: 'center',
                        lineHeight: 20,
                        rich: {
                            c: {

                            },
                        },

                    },
                    itemStyle: {
                        normal: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(232, 94, 106, .8)', //  0%  处的颜色
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(232, 94, 106, .1)', //  100%  处的颜色
                                },
                                ],
                                global: false, //  缺省为  false
                            },
                        },
                        emphasis: {
                            opacity: 1,
                        },
                    },
                    data: data,
                    z: 20,
                }],
            };
            myChart.setOption(option);
        },
        Chart1() {
            const myChart = this.$echarts.init(document.getElementById('myChart1'));
            this.contractTypeData.forEach(item => {
                if (item.name === '临时赊销合同') {
                    item.itemStyle = {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                offset: 0,
                                color: '#EA8343',
                            }, {
                                offset: 1,
                                color: '#F0AD55',
                            }]),
                        },
                    };
                } else if ( item.name === '零售合同') {
                    item.itemStyle = {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                offset: 0,
                                color: '#499F93',
                            }, {
                                offset: 1,
                                color: '#76CAB3',
                            }]),
                        },
                    };
                } else if ( item.name === '现销合同') {
                    item.itemStyle = {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                offset: 0,
                                color: '#48A0D8',
                            }, {
                                offset: 1,
                                color: '#7FD0FE',
                            }]),
                        },
                    };
                } else if ( item.name === '正式赊销合同') {
                    item.itemStyle = {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{ // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                offset: 0,
                                color: '#3E78C3',
                            }, {
                                offset: 1,
                                color: '#44A1F5',
                            }]),
                        },
                    };
                }
            });
            const ydata = this.contractTypeData;
            // const color = ['#FE7903', '#1577D2', '#4CA295', '#4BA2DA'];
            // const xdata = ['临时合同', '正式合同', '现销合同', '正式赊销合同'];
            const option = {
                // backgroundColor: 'rgba(255,255,255,1)',
                // color: color,
                legend: [{
                    icon: 'square',
                    orient: 'horizontal',
                    x: 'center',
                    top: 'bottom',
                    left: '10%',
                    bottom: '0%',
                    // data: xdata,
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 10,
                    formatter: function (name) {
                        return '' + name;
                    },
                    textStyle: {
                        color: '#000000',
                        fontWeight: 'bold',
                        fontSize: 12,
                        lineHeight: 14,
                    },
                }, {
                    icon: 'none',
                    orient: 'horizontal',
                    x: 'center',
                    top: '10%',
                    left: '50%',
                    bottom: '0%',
                    // itemWidth: 12,
                    // itemHeight: 12,
                    // itemGap: 10,
                    // width: 10,
                    formatter: function (name) {
                        let value;
                        for (let i = 0, l = ydata.length; i < l; i++) {
                            if (ydata[i].name === name) {
                                value = ydata[i].value;
                            }
                        }
                        let arr = [];
                        switch (name) {
                            case '临时赊销合同':
                                arr = [
                                    '{a|' + name + '}',
                                    '{b1|' + value + '}',
                                ];
                                break;
                            case '零售合同':
                                arr = [
                                    '{a|' + name + '}',
                                    '{b2|' + value + '}',
                                ];
                                break;
                            case '现销合同':
                                arr = [
                                    '{a|' + name + '}',
                                    '{b3|' + value + '}',
                                ];
                                break;
                            case '正式赊销合同':
                                arr = [
                                    '{a|' + name + '}',
                                    '{b4|' + value + '}',
                                ];
                                break;
                        }

                        return arr.join('\n');
                    },
                    textStyle: {
                        // color: '#000000',
                        // fontWeight: 'bold',
                        // fontSize: 12,
                        // lineHeight: 14,
                        // padding: 6,
                        rich: {
                            a: {
                                fontSize: 12,
                                color: '#666666',
                            },
                            b1: {
                                backgroundColor: {
                                    image: bg1,
                                },
                                fontSize: 14,
                                padding: 4,
                                align: 'center',
                                lineHeight: 28,
                                color: '#FC863C',
                            },
                            b2: {
                                backgroundColor: {
                                    image: bg2,
                                },
                                fontSize: 14,
                                padding: 4,
                                align: 'center',
                                lineHeight: 28,
                                color: '#4DA396',
                            },
                            b3: {
                                backgroundColor: {
                                    image: bg3,
                                },
                                fontSize: 14,
                                padding: 4,
                                align: 'center',
                                lineHeight: 28,
                                color: '#3E7BC6',
                            },
                            b4: {
                                backgroundColor: {
                                    image: bg4,
                                },
                                fontSize: 14,
                                padding: 4,
                                align: 'center',
                                lineHeight: 28,
                                color: '#45AAEB',
                            },
                        },
                    },
                } ],
                series: [{
                    type: 'pie',
                    clockwise: false, // 饼图的扇区是否是顺时针排布
                    minAngle: 2, // 最小的扇区角度（0 ~ 360）
                    radius: ['52%', '64%'],
                    center: ['30%', '46%'],
                    avoidLabelOverlap: false,
                    itemStyle: { // 图形样式
                        normal: {
                            borderColor: '#ffffff',
                            borderWidth: 1,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            formatter: '({d}%)\n{text|{b}}',
                            rich: {
                                text: {
                                    color: '#333333',
                                    fontSize: 10,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    padding: 4,
                                },
                                value: {
                                    color: '#333333',
                                    fontSize: 10,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: 20,
                                color: '#000033',
                            },
                        },
                    },
                    data: ydata,
                }],
            };
            myChart.setOption(option);
        },
        handleSizeChange(val) {
            this.page.pagesize = val;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page.pageindex = val;
            this.getTableData();
        },
        timeFormat(date) {
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            return `${y}-${m}-${d}`;
        },
    },
};
</script>

<style lang="stylus" scope>
    .control-wrapper
        // position relative
        .maske
            width 100%
            height 100%
            position fixed
            left 0
            top 0
            background rgba(0,0,0,0.7)
            z-index 99
        .dialog-wrapper
            position fixed
            width 9.2rem
            height 6.6rem
            background rgba(255,255,255,.5)
            left 50%
            top 50%
            margin-left -4.6rem
            margin-top -3.3rem
            padding 0.1rem
            z-index 100
            .dialog-box
                width 100%
                height 100%
                background #fff
                padding 0.3rem 0
                display flex
                flex-direction column
                .header-title
                    height 0.4rem
                    line-height 0.4rem
                    h4
                        color #000
                        font-weight 600
                        margin-left 0.38rem
                        font-size 0.2rem
                .section-main
                    flex 1
                    overflow hidden
                    .list
                        overflow-y auto
                        height 100%
                        .item
                            border-bottom 1px solid rgba(240,240,240,1)
                            padding 0.2rem 0
                            &:last-child
                                border-bottom none
                            .item-box
                                padding 0 0.38rem
                                display flex
                                .left-logo
                                    width 0.6rem
                                    .logo-wrapper
                                        width 0.5rem
                                        height 0.5rem
                                        background rgba(235,101,111,0.2)
                                        border-radius 50%
                                        overflow hidden
                                        .logo-bg
                                            width 0.36rem
                                            height 0.36rem
                                            background rgba(235,101,111,1)
                                            border-radius 50%
                                            margin 0.07rem
                                            line-height 0.30rem
                                            text-align center
                                            i
                                                color #ffffff
                                                font-size 0.14rem
                                .right-main
                                    flex 1
                                    h6
                                        font-size 0.18rem
                                        line-height 0.3rem
                                        font-weight 600
                                    em
                                        font-size 0.14rem
                                        color rgba(151,158,167,1)
                                        // line-height 0.24rem
                                        margin 0.1rem 0
                                    p
                                        font-size 0.16rem
                                        color rgba(51,51,51,1)
                                        line-height 0.24rem
                                    .operator-wrapper
                                        display flex
                                        justify-content flex-end
                                        margin-top 0.1rem
                                        .operator
                                            padding 0.04rem
                                            background rgba(2,133,254,0.08);
                                            font-size 0.12rem
                                            display inline-block
                                            color #0285FE
                                            margin-right 0.2rem
                                            i
                                                font-size 0.12rem
                                        .time
                                            padding 0.04rem
                                            border 1px solid rgba(221,221,221,1);
                                            font-size 0.12rem
                                            display inline-block
                                            color #A77B54
                                            i
                                                font-size 0.12rem

                            .yj
                                .right-main
                                    h6
                                        color #D6000F
                                .left-logo
                                    .logo-wrapper
                                        background rgba(235,101,111,0.2)
                                        .logo-bg
                                            background rgba(235,101,111,1)
                            .gk
                                .right-main
                                    h6
                                        color #1577D2
                                .left-logo
                                    .logo-wrapper
                                        background rgba(85,136,241,0.2)
                                        .logo-bg
                                            background rgba(85,136,241,1)
                            .js
                                .right-main
                                    h6
                                        color #FF6600
                                .left-logo
                                    .logo-wrapper
                                        background rgba(250,132,54,0.2)
                                        .logo-bg
                                            background rgba(250,132,54,1)
                .textarea-wrapper
                    // height 1.6rem
                    // background pink
                    padding 0.2rem 0.38rem
                    .textarea-box
                        width 100%
                        height 100%
                        // border 1px solid rgba(215, 215, 215, 1)
                .footer-btn
                    height 0.44rem
                    display flex
                    justify-content center
                    .btn
                        width 1.84rem
                        height 0.44rem
                        border 0.02rem solid rgba(21,119,210,1)
                        color #1577D2
                        background #fff
                        // margin 0 auto
                        display inline-block
                        cursor pointer
                        margin 0 0.2rem
                    .confirm_btn
                        background #1577D2
                        color #ffffff
                        border none

        .control-main
            height 100%
            width 100%
            display flex
            flex-direction column
            .header-wrapper
                width 100%
                height 0.6rem
                display flex
                padding 0 0.1rem
                .block-item
                    background #ffffff
                    box-shadow 0px 2px 6px 0px rgba(85,136,241,0.5)
                    height 0.6rem
                    transform skewX(-15deg)
                    -ms-transform skewX(-15deg)
                    -moz-transform skewX(-15deg)
                    -webkit-transform skewX(-15deg)
                    -o-transform skewX(-15deg)
                    &.left_block
                        width 0.4rem
                    &.center_block
                        flex 1
                        margin 0 0.1rem
                        p
                            text-align center
                            line-height 0.6rem
                            transform skewX(15deg)
                            -ms-transform skewX(15deg)
                            -moz-transform skewX(15deg)
                            -webkit-transform skewX(15deg)
                            -o-transform skewX(15deg)
                            font-size 0.24rem
                            span
                                width 0.28rem
                                height 0.36rem
                                background rgba(32,150,243,0.1)
                                border 1px solid rgba(32,150,243,1)
                                display inline-block
                                color #2096F3
                                line-height 0.34rem
                                margin-left 0.1rem
                                font-size 0.26rem
                    &.right_block
                        width 5rem
                        .el-date-editor
                            width 4rem !important
                            margin 0.12rem 0.5rem
                            transform skewX(15deg)
                            -ms-transform skewX(15deg)
                            -moz-transform skewX(15deg)
                            -webkit-transform skewX(15deg)
                            -o-transform skewX(15deg)
            .content-wrapper
                height 2.8rem
                display flex
                margin 0.1rem 0
                .content-item
                    background #ffffff
                    box-shadow 0px 2px 6px 0px rgba(85,136,241,0.5)
                    flex 1
                    padding 0.2rem
                    display flex
                    flex-direction column
                    overflow hidden
                    p
                        color #333333
                        font-size 0.18rem
                        font-weight 900
                        position relative
                        padding-left 0.2rem
                        height 0.2rem
                        line-height 0.2rem
                        &::after
                            content ''
                            width 0.06rem
                            height 0.2rem
                            background rgba(21,119,210,1)
                            position absolute
                            left 0
                            top 0.01rem
                    .chart
                        flex 1
                        margin-top 0.1rem
                    &.center_content
                        margin 0 0.1rem
            .section-wrapper
                background #ffffff
                box-shadow 0px 2px 6px 0px rgba(85,136,241,0.5)
                flex 1
                display flex
                flex-direction column
                padding 0.2rem
                .table-wrapper
                    flex 1
                    // border 1px solid rgba(215,215,215,1)
                    width 100%
                    .el-table
                        .el-table__body-wrapper
                            .el-table__body
                                td
                                    padding 0
                .page-wrapper
                    text-align right
                    margin-top 0.1rem
        </style>